import React from "react"
import { Container, Row, Col } from "reactstrap"

class FeaturesCard extends React.Component {
  createCards = () => {
    let features = []
    let cards = [
      {
        icon: "paint-bucket",
        title: "Design",
        description:
          "No matter if its a new look, a logo, or a complete rebrand we have what it takes to push your site past the rest. Let us know how we can bring life to your business.",
      },
      {
        icon: "science",
        title: "Develop",
        description:
          "Web development is the cornerstone of what we do. We are ready to elevate your business past the rest. Leave the coding to us, and you wont regret it.",
      },
      {
        icon: "server",
        title: "Deliver",
        description:
          "Upon completion we promise to deliver your solution in every way we promised, leaving your business where it should be. In our hands.",
      },
    ]

    cards.forEach((el, i) => {
      features.push(
        <Col md="4" key={i}>
          <div className="icon-box rounded gradient gradient-primary-light text-contrast shadow icon-xl">
            <i className={`icon m-0 pe pe-7s-${el.icon} pe-3x`} />
          </div>
          <h4 className="semi-bold mt-4 text-capitalize cta-title">
            {el.title}
          </h4>

          <hr className="w-25 bw-2 border-alternate mt-3 mb-4" />
          <p className="regular text-muted">{el.description}</p>
        </Col>
      )
    })

    return features
  }

  render() {
    return (
      <section className="section mt-6n" id="features">
        <Container className="pt-0">
          <div className="bg-contrast shadow rounded p-5">
            <Row className="gap-y">{this.createCards()}</Row>
          </div>
        </Container>
      </section>
    )
  }
}

export default FeaturesCard
