import React from "react"
import { Container, Row, Col } from "reactstrap"
//import Tilt from "react-tilt";
import Fade from "react-reveal/Fade"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const MultipleDesignBlocks = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "JAMStack.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section className="section powered-design">
      <div className="shapes-container">
        <div className="shape shape-circle shape-circle-1">
          <Fade bottom right duration={1500}>
            <div />
          </Fade>
        </div>
        <div className="shape shape-circle shape-circle-2">
          <Fade bottom right duration={1200} delay={500}>
            <div />
          </Fade>
        </div>
        <div className="shape shape-ring animation--rotating-diagonal">
          <div />
        </div>
        <div className="shape shape-triangle shape-animated">
          <div className="animation--rotating" />
        </div>

        <div className="shape pattern-dots-1" />
      </div>

      <Container>
        <Row className="gap-y align-items-center">
          <Col md="6">
            <div className="section-heading">
              <h2 className="heading-line">Built for speed on the JAMStack</h2>
              <p className="lead text-muted">
                At Stack Bit Labs we build our sites on the JAMStack. This
                allows us to create blazing fast websites, so fast it just
                might scare you. Find out what the stack could mean for your
                website.
              </p>
            </div>

            <a
              className="btn btn-outline-alternate more-link mt-0"
              href="https://jamstack.org/"
              target="_blank"
              rel="noopener noreferrer"
            >Learn the Stack</a>
          </Col>

          <Col md="6">
            <Fade bottom>
              <Img fluid={data.placeholderImage.childImageSharp.fluid} alt="JAMStack" />
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default MultipleDesignBlocks
