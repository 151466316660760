import React from "react"
import { Container, Row, Col, Nav } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Fade from "react-reveal/Fade"
import Typist from "react-typist"
import scrollTo from 'gatsby-plugin-smoothscroll'

const animations = [
  { duration: 3500, delay: 100 },
  { duration: 2000, delay: 100 },
  { duration: 2000, delay: 200 },
  { duration: 2000, delay: 200 },
  { duration: 2000, delay: 100 },
  { duration: 2000, delay: 100 },
  { duration: 2000, delay: 300 },
  { duration: 1000, delay: 200 },
  { duration: 1000, delay: 100 },
  { duration: 4000, delay: 500 },
  { duration: 1000, delay: 200 },
  { duration: 1000, delay: 100 },
  { duration: 1000, delay: 0 },
  { duration: 1000, delay: 0 },
  { duration: 1000, delay: 100 },
  { duration: 1000, delay: 0 },
]

const Heading = () => {
  // const data = useStaticQuery(graphql`
  //     query {
  //       placeholderImage: file(relativePath: { eq: "HeroFinal.png" }) {
  //         childImageSharp {
  //           fluid(maxWidth: 580) {
  //             ...GatsbyImageSharpFluid
  //           }
  //         }
  //       }
  //     }
  //   `)

  return (
    <header className="header alter1-header section text-contrast">
      <div className="shapes-container">
        {animations.map((el, i) => (
          <Fade bottom duration={el.duration} delay={el.delay} key={i}>
            <div className="shape shape-animated shape-diagonal" key={i} />
          </Fade>
        ))}
      </div>

      <Container className="container">
        <Row>
          <Col md="12">
            <span className="rounded-pill shadow-box bg-contrast text-dark bold py-2 px-4 hero-lead">
              <FontAwesomeIcon
                icon={["far", "lightbulb"]}
                className="text-primary mr-2"
              />
              <span className="text-primary">Contact</span> us today to learn
              more
            </span>

            <h1 className="text-contrast display-4 display-md-3 main-heading">
              <span className="bold">
                <Typist cursor={{ hideWhenDone: true }} avgTypingDelay={60}>
                  We offer solutions for your business.
                </Typist>
              </span>
            </h1>
            {/* <p className="lead bold">Stacking Bits &amp; Taking Names</p> */}

            <p className="lead">
              We are a Web Development company that is dedicated to taking your
              business problems and turning them into solutions. We deliver more
              than just a website, we deliver results.
            </p>

            <Nav className="my-5 hero-cta-buttons" tag="nav">
              <div
                tabIndex={0}
                role="button"
                onClick={() => scrollTo('#contact-form')}
                onKeyDown={() => scrollTo('#contact-form')}
                className="btn btn-rounded btn-alternate mr-2 mr-md-5"
              >
                Contact now
                <FontAwesomeIcon
                  icon={["fas", "long-arrow-alt-right"]}
                  className="ml-2"
                />
              </div>
              {/* <a
                href="#demos"
                className="btn btn-rounded btn-contrast scrollto"
              >
                Explore case studies
              </a> */}
            </Nav>
          </Col>

          {/* <Col md="6">
            <div>
            
            <div className="iphone light" style={{maxWidth:"500px"}}>
              <div className="screen shadow-box">
              <div style={{width:"100%",height:"0",paddingBottom:"75%",position:"relative"}}>
              <iframe title="gif" src="https://giphy.com/embed/EeKAJaMxmVLwc" width="100%" height="100%" style={{position:"absolute"}} ></iframe>
            </div>
              </div>
              <div className="notch" />
            </div>
            </div>
          </Col> */}
        </Row>
      </Container>
    </header>
  )
}

export default Heading
