import React from "react"

import Layout from "../components/global/layout"
import SEO from "../components/global/seo"

import Hero from "../components/hero"
import CallToAction from "../components/callToAction"
import Proposal from "../components/proposal"
import PowerfulFeatures from "../components/powerfulFeatures"
import MultipleDesignBlocks from "../components/multipleDesignBlocks"
import ContactForm from "../components/contactForm"

const IndexPage = () => (
  <Layout>
    <SEO title="Web Design, Mobile Apps, and Software" />
    <Hero />
    <CallToAction />
    <Proposal />
    <PowerfulFeatures />
    <MultipleDesignBlocks />
    <ContactForm />
  </Layout>
)

export default IndexPage
