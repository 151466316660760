import React from "react"
import { Container } from "reactstrap"

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      status: ""
    };

    this.submitForm = this.submitForm.bind(this);
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader("Accept", "application/json");
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: "SUCCESS" });
      } else {
        this.setState({ status: "ERROR" });
      }
    };
    xhr.send(data);
  }
  render() {
    const { status } = this.state;
    return (
    <section className="section stay-tunned" id="contact-form">
      <Container className="bring-to-front">
        <div className="shadow rounded p-5 bg-contrast overflow-hidden">

          <div className="section-heading text-center">
            <h2 className="heading-line">Contact us today</h2>
            <p className="lead text-alternate">
              Drop us a line so we can elevate your business to the next level!
            </p>
          </div>

          <div className="mx-auto position-relative form-wrapper">
            <form
                onSubmit={this.submitForm}
                action="https://formspree.io/xeqaypag"
                method="POST"
                className="form text-center"
                data-response-message-animation="slide-in-left">

                <div className="form-group input--group">
                    <input type="email" name="email" className="form-control form-control-lg text-center" placeholder="Email"/>
                    <textarea name="message" rows="3" cols="30" type="text" className="form-control form-control-lg text-center" placeholder="Message"/>
                </div>

                {status === "SUCCESS" ? <p>We will be in touch soon!</p> : <button className="btn btn-lg btn-alternate">Submit</button>}
                {status === "ERROR" && <p>Ooops! There was an error.</p>}

            </form>
          </div>
        </div>
      </Container>
    </section>
    );
  }
}

export default ContactForm